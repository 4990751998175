<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio cliente</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Dettaglio cliente</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>

      <div v-else>
        <ion-card>
          <ion-card-header class="pb-0 bg-primary">
            <ion-card-title>
              <ion-text color="light">
                <p class="card_title">
                  {{ riferimentoCliente(cliente) }}
                </p>
              </ion-text>
            </ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <ion-list>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Codice</ion-label>
                <ion-text>
                  <p class="base_text">
                    {{ checkField(cliente.customers_code) }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Città</ion-label>
                <ion-text>
                  <p class="base_text">
                    {{ cliente.customers_city }},
                    {{ cliente.customers_province }}
                  </p>
                </ion-text>
              </ion-item>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Indirizzo</ion-label>
                <ion-text>
                  <p class="base_text">
                    <a
                      :href="`https://maps.google.com/?q=${cliente.customers_city} ${cliente.customers_address} ${cliente.customers_zip_code}`"
                      target="_blank"
                      class="mobile_link"
                    >
                      {{ cliente.customers_address }}
                    </a>
                  </p>
                </ion-text>
              </ion-item>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Telefono</ion-label>
                <ion-text>
                  <p class="base_text">
                    <a
                      v-if="cliente.customers_phone"
                      :href="'tel:' + cliente.customers_phone"
                      class="mobile_link"
                      >{{ checkField(cliente.customers_phone) }}
                    </a>
                    <span v-else>
                      {{ checkField(cliente.customers_phone) }}
                    </span>
                  </p>
                </ion-text>
              </ion-item>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Cellulare</ion-label>
                <ion-text>
                  <p class="base_text">
                    <a
                      v-if="cliente.customers_mobile"
                      :href="'tel:' + cliente.customers_mobile"
                      class="mobile_link"
                      >{{ checkField(cliente.customers_mobile) }}
                    </a>
                    <span v-else>
                      {{ checkField(cliente.customers_mobile) }}
                    </span>
                  </p>
                </ion-text>
              </ion-item>
              <ion-item lines="full" class="ion-no-padding">
                <ion-label color="primary">Email</ion-label>
                <ion-text>
                  <p class="base_text">
                    {{ checkField(cliente.customers_email) }}
                  </p>
                </ion-text>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>
      </div>

      <!-- <ion-card>
        <ion-card-header class="bg-primary">
          <ion-card-title>
            <ion-text color="light">
              <p class="card_title">Elenco impianti</p>
            </ion-text>
          </ion-card-title>
        </ion-card-header>

        <ion-card-content>
          <div v-if="loadingCommesse" class="loading_spinner_commesse">
            <ion-spinner name="crescent" color="primary"></ion-spinner>
          </div>
          <div v-else>
            <div
              v-if="impianti.length == 0"
              class="ion-text-center ion-margin-top"
            >
              <ion-text color="danger">
                Il cliente selezionato non ha impianti associati
              </ion-text>
            </div>
            <div v-else>
              <div
                v-for="impianto in impianti"
                :key="impianto.projects_id"
                class="impianto ion-padding-top"
                @click="openCommessaDetail(impianto.projects_id)"
              >
                <div class="impianto_header">
                  <ion-text color="primary">
                    <h2>
                      <strong> Impianto {{ impianto.projects_codice }} </strong>
                    </h2>
                  </ion-text>
                </div>
                <ion-list>
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary"> Tipologia </ion-label>
                    <ion-text>
                      <p class="base_text">
                        {{ checkField(impianto.projects_type_value) }}
                      </p>
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary"> Indirizzo </ion-label>
                    <ion-text>
                      <p class="base_text" style="text-align: right">
                        {{ impianto.projects_impianto_indirizzo }},<br />
                        {{ impianto.comuni_label }}
                      </p>
                    </ion-text>
                  </ion-item>
                  <ion-item class="ion-no-padding">
                    <ion-label color="primary">Stato</ion-label>
                    <ion-text>
                      <p class="base_text" v-if="impianto.projects_status_id">
                        <ion-badge
                          :color="statoImpianto(impianto.projects_status_id)"
                        >
                          {{ impianto.projects_status_value }}
                        </ion-badge>
                      </p>
                      <p class="base_text" v-else>-</p>
                    </ion-text>
                  </ion-item>
                </ion-list>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonList,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/vue";

import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

// import apiCommesse from "../services/commesse";
// import { openToast } from "../services/toast";
import { getCustomer } from "../services/localbase";

export default {
  name: "ClienteDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonList,
    IonItem,
    IonLabel,
    IonText,
  },
  setup() {
    const route = useRoute();
    const id = route.params.id; //customers_id by route params
    const router = useRouter();
    const loading = ref(false);
    //const loadingCommesse = ref(false);
    const cliente = ref([]);
    //const impianti = ref([]);

    function riferimentoCliente(cliente) {
      if (cliente.customers_company) {
        return `${cliente.customers_company}`;
      } else if (cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_name} ${cliente.customers_last_name}`;
      } else if (cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_name}`;
      } else if (!cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_last_name}`;
      }
    }

    //Set correct background for impianto status
    const statoImpianto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 9) {
          //contrattualizzato
          className = "warning";
        } else if (statusId == 10) {
          className = "danger";
        } else if (statusId == 11) {
          className = "secondary";
        } else if (statusId == 12) {
          className = "dark";
        } else {
          className = "success";
        }
        return className;
      };
    });

    //Return '-' if a customer field is null or empty
    function checkField(field) {
      if (field == null || field == "") {
        return "-";
      } else {
        return field;
      }
    }

    //Passing id to retrive detail in ClienteDetal page
    function openCommessaDetail(idCommessa) {
      router.push(`${id}/commessa/${idCommessa}`);
    }

    async function getCliente() {
      loading.value = true;
      const customer = await getCustomer(id);
      return customer;
    }
    //Get customer data from indexedDB
    function getClienteDetail(id) {
      getCliente()
        .then((customer) => {
          cliente.value = customer;
        })
        .then(() => {
          loading.value = false;
        });
    }
    getClienteDetail(id);

    onMounted(() => {
      /* GET CUSTOMER COMMESSE */
      // loadingCommesse.value = true;
      // apiCommesse
      //   .getClienteCommesse(id)
      //   .then((response) => {
      //     impianti.value = response;
      //   })
      //   .catch((error) => {
      //     error.value = true;
      //     openToast("Errore durante la richiesta degli impianti", "danger");
      //   })
      //   .finally(() => {
      //     loadingCommesse.value = false;
      //   });
    });

    return {
      id,
      loading,
      //loadingCommesse,
      riferimentoCliente,
      //openCommessaDetail,
      cliente,
      //impianti,
      //statoImpianto,
      checkField,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_spinner_commesse {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.impianto {
  margin-bottom: 5px;
}
.impianto_codice {
  margin-bottom: 0;
}
.impianto_header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.base_text {
  font-size: 14px;
}
.mobile_link {
  text-decoration: none;
  color: inherit;
}

.pb-0 {
  padding-bottom: 0;
}

.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}
</style>